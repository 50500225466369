// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.core();

@import 'variables.scss';
@import '/src/app/shared/directives/directives.scss';
@import '/src/form-field-input-fix.scss';


$my-primary: mat.m2-define-palette($primary, 500);
$my-accent: mat.m2-define-palette($accent, 500);
$my-warn: mat.m2-define-palette(mat.$m2-red-palette);

$my-level: mat.m2-define-typography-level(
  $font-family: Roboto,
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);

$my-headline-1: mat.m2-define-typography-level(
  $font-family: Roboto,
  $font-weight: 500,
  $font-size: 20px,
  $line-height: 1,
  $letter-spacing: normal,
);

$my-typography: mat.m2-define-typography-config(
    $font-family: Roboto,
    $headline-1: $my-headline-1,
    $headline-2: $my-level,
    $headline-3: $my-level,
    $headline-4: $my-level,
    $headline-5: $my-level,
    $headline-6: $my-level,
    $subtitle-1: $my-level,
    $subtitle-2: $my-level,
    $body-1: $my-level,
    $body-2: $my-level,
    $caption: $my-level,
    $button: $my-level,
    $overline: $my-level,
  );

$my-theme: mat.m2-define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    warn: $my-warn
  ),
  typography: $my-typography
 ));

 @include mat.all-component-themes($my-theme);
 @include mat.dialog-typography($my-typography);

.mat-success {
  color: $success;
}

.mat-critical {
  color: $critical;
}

.mat-caution {
  color: $caution;
}

.mat-serious {
  color: $serious;
}

.mat-normal-background {
  background-color: $normal
}

.mat-standby-background {
  background-color: $standby;
}

.mat-critical-background {
  background-color: $critical;
}

.mat-caution-background {
  background-color: $caution;
}

.mat-serious-background {
  background-color: $serious;
}

html, body { height: 100%; }
body { margin: 0; }
* { font-family: "Roboto", "Helvetica Neue", sans-serif; }

.Flexbox {
  display: flex;

  &--row {
    flex-direction: row;

    &-center {
      align-items: center;
    }
  }

  &--column {
    flex-direction: column;

    @media print {
      display: block;
    }

    &-center {
      justify-content: center;
    }
  }

  &--align-center {
    align-items: center;
  }

  &--align-flex-start {
    align-items: flex-start;
  }

  &--align-flex-end {
    align-items: flex-end;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-right {
    justify-content: right;
  }

  &--justify-left {
    justify-content: left;
  }
}

.PageTitle {
  margin-left: 2rem;
}

button {
  text-transform: uppercase;
}

h1,h2,h3 {
  text-transform: uppercase;
}

.BigDisclaimer {
  font-size: 9px;
}

.Strikethrough {
  text-decoration: line-through;
}

body {
  min-width: 700px;
}
