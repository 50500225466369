$primary: (
    50 : #e7eeef,
    100 : #c4d5d8,
    200 : #9dbabe,
    300 : #769ea3,
    400 : #588990,
    500 : #3b747c,
    600 : #356c74,
    700 : #2d6169,
    800 : #26575f,
    900 : #19444c,
    A100 : #8bebff,
    A200 : #58e2ff,
    A400 : #25daff,
    A700 : #0bd5ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$accent: (
    50 : #e7eaef,
    100 : #c4ccd8,
    200 : #9daabe,
    300 : #7687a3,
    400 : #586e90,
    500 : #3b547c,
    600 : #354d74,
    700 : #2d4369,
    800 : #263a5f,
    900 : #19294c,
    A100 : #8badff,
    A200 : #5889ff,
    A400 : #2566ff,
    A700 : #0b54ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$top-bar-height: 99px;
$divider-color: #cdcdcd;
$light-text: #8b8b8b;
$success: #2e7d32;
$critical: #ff3838;
$normal: #56f000;
$standby: #2dccff;
$caution: #fce83a;
$serious: #ffb302;
