$initial-width: 8.5ch;
$expanded-width: 35ch;

@mixin expandable-field-initial {
  width: $initial-width;
  animation: accordion-close 0.15s ease-out;
}

@mixin expandable-field-expanded {
  width: $expanded-width;
  animation: accordion-open 0.1s ease-out;
}

[appExpandableField] {
  mat-form-field {
    @include expandable-field-initial();
  }

  &.Expanded {
    mat-form-field {
      @include expandable-field-expanded();
    }
  }
}

input[appExpandableField], mat-form-field[appExpandableField] {
  @include expandable-field-initial();

  &.Expanded {
    @include expandable-field-expanded();
  }
}

@keyframes accordion-open {
  0% {
      width: $initial-width;
  }
}

@keyframes accordion-close {
  0% {
    width: $expanded-width;
  }
}
